/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          
          
          // browser fixes
          
          var isIE = document.all && !window.atob; // find IE <= 9
          
          
          // skip to content
           // bind a click event to the 'skip' link
          $(".skip").click(function(event){

            // strip the leading hash and declare
            // the content we're skipping to
            var skipTo="#"+this.href.split('#')[1];

            // Setting 'tabindex' to -1 takes an element out of normal 
            // tab flow but allows it to be focused via javascript
            $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

              // when focus leaves this element, 
              // remove the tabindex attribute
              $(this).removeAttr('tabindex');

            }).focus(); // focus on the content container
          });
          
          /* Page Builder Sticky Nav */
          /* highlight the top nav as scrolling occurs */
            $('body').scrollspy({ target: '#ut-sticky-navbar' });

            /* smooth scrolling for scroll to top */
            $('section.dynamicSection .ut-logo-small a').click(function(e){
                e.preventDefault();
              $('body,html').stop(true).animate({scrollTop:0},1000);
                return false;
            });

            /* smooth scrolling for nav sections */
            $('section.dynamicSection .navbar-nav li>a').click(function(e){
                e.preventDefault();
              var link = $(this).attr('href');
              var posi = $(link).offset().top - 74;
              $('body,html').stop(true).animate({scrollTop:posi},700);
                return false; 
            });
          
          
          // hamburger menu animation
          document.querySelector( ".navbar-default .navbar-toggle" )
              .addEventListener( "click", function() {
                document.querySelector("#nav-toggle").classList.toggle( "active" );
                document.querySelector(".navbar-default .navbar-toggle .mobile-only").classList.toggle( "active" );
                    
                    var menuText = document.querySelectorAll(".navbar-default .navbar-toggle .mobile-only")[0];
                      if (menuText.innerHTML === "MENU") {
                        menuText.innerHTML = "CLOSE";
                      } else {
                        menuText.innerHTML = "MENU";
                      }
              
              });
          
          document.querySelector(".navbar-sidebar-menu .navbar-toggle")
            .addEventListener("click", function() {
                document.querySelector(".navbar-sidebar-menu #nav-toggle").classList.toggle( "active" );
                document.querySelector(".navbar-sidebar-menu .navbar-toggle .mobile-only").classList.toggle( "active" );
          });
          
          /* sidebar menu accordion */
          
          $('.ut-custom-menu-widget ul li.menu-item-has-children').click(function() {
              $('.ut-custom-menu-widget li').removeClass('active');
              $(this).addClass('active');	
              var checkElement = $(this).find('a').next();
              if((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                $(this).closest('li').removeClass('active');
                checkElement.slideUp('normal');
              }
              if((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                $('.ut-custom-menu-widget ul ul:visible').slideUp('normal');
                checkElement.slideDown('normal');
              }
              if($(this).is( 'li' ).closest('li').find('ul').children().length === 0) {
                return true;
              } else {
                return false;	
              }		
          });
          
          /* move footerLinks to headerTop above 600px */
          //var width = $(window).width();
          var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
          var width = x;
          			
            if (width >= 899) {
				var footerLinks = $('.footerLinks').detach();
                $('header.banner').before(footerLinks);
            }
          
          
            /* responsive logo */
            if (width >= 1121) {
                $('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/ut-advocacy/dist/images/ut-advocacy.png');
            }
            else if(  (width >= 600) && (width <= 1120) )
            {
                $('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/ut-advocacy/dist/images/ut-advocacy.png');
            }
          
            /* responsive sidebar menu */
              if (width >= 600) {
                $('.page-template-template-2-column-menu .main').css("min-height", $('.page-template-template-2-column-menu .sidebar-menu').height() + 14);
              
              }
          
            var searchForm = $('header .widget').detach();
            
            if (width <= 600) {
                $('.navbar-brand').before(searchForm);
            }
            else
            {
                $('.navbar-header').after(searchForm);
            }
            
            var utNav = $('#utNav').detach();
            
            var sideNavEl = $('section.ut-custom-menu-widget');
                sideNavEl.addClass("navbar-ex2-collapse");
                var sideNav = sideNavEl.detach();
            var sideSearch = $('.sidebar-menu section.widget_search');
                sideSearch.addClass("navbar-collapse collapse navbar-ex2-collapse");
                var sideSearchEl = sideSearch.detach();
            
            if (width <= 600) {
                $('.navbar-sidebar-menu').after(sideSearchEl);
                $('#catNav').after(utNav);
                $('.navbar-sidebar-menu').after(sideNav);
                
                    // sidenav header
                var sideNavHead = $('.navContainer .ut-custom-menu-widget h3').html();
                $('.navbar-sidebar-menu button .mobile-only').html('<h3>' + sideNavHead + '</h3>'); 
            }
            else
            {
                $('header section.widget').after(utNav);
                $('aside.sidebar-menu').prepend(sideSearchEl);
                $('aside.sidebar-menu').prepend(sideNav);
            }
          
          
            
          
          /* responsive */
          
          $(window).resize(function(){

					//var width = $(window).width(); 
                    var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
                    var width = x;
					
					var height = $(window).height();
					
					/* manage header nav location */
			         var footerLinks = $('.footerLinks').detach();
              
				    if (width >= 899) {
						$('header.banner').before(footerLinks);
				    }
				    else
					{
						/* put back where it goes */
				        $('footer.content-info').after(footerLinks);
					}
              
              
                    /* responsive logo */
                    if (width >= 1121) {
						$('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/ut-advocacy/dist/images/ut-advocacy.png');
				    }
                    else if(  (width > 600) && (width <= 1120) )
                    {
                        $('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/ut-advocacy/dist/images/ut-advocacy.png');
                    }
				    else
					{
						/* put back where it goes */
				        $('.logo').attr('src', 'http://utmain.wpengine.com/wp-content/themes/ut-advocacy/dist/images/ut-advocacy.png');
					}
              
                    var searchForm = $('header .widget').detach();
            
                    if (width <= 600) {
                        $('.navbar-brand').before(searchForm);
                    }
                    else
                    {
                        $('.navbar-header').after(searchForm);
                    }
              
                    var utNav = $('#utNav').detach();
            
                    if (width <= 600) {
                        $('.navbar-sidebar-menu').after(sideSearchEl);
                        $('#catNav').after(utNav);
                        $('.navbar-sidebar-menu').after(sideNav);
                        
                        var sideNavHead = $('.navContainer .ut-custom-menu-widget h3').html();
                        $('.navbar-sidebar-menu button .mobile-only').html('<h3>' + sideNavHead + '</h3>'); 
                    }
                    else
                    {
                        $('header section.widget').after(utNav);
                        $('aside.sidebar-menu').prepend(sideSearchEl);
                        $('aside.sidebar-menu').prepend(sideNav);
                    }
                
          });
          
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
